@font-face {
  font-family: 'Levit_Blended';
  font-weight: 400 700;
  descent-override: 1%;
  src: url(./Levit_Blended-Bold.otf) format("opentype");
}

* {
  box-sizing: border-box;
}

html, body, #root {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  scroll-behavior: smooth;

  font-family: 'Levit_Blended';
  font-size: 32px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  opacity: 0.6;
}

ul, li {
  list-style-type: none;
  margin: 0;
}
