/* GENERAL */
.text-big {
  font-size: 100px;
  line-height: 72px;
  text-transform: capitalize;
  color: black;
  display: inline
}

.text-small {
  font-size: 32px;
  line-height: 32px;
  text-transform: capitalize;
}

.box-small {
  border-radius: 10px;
  padding: 4px;
  display: inline-block;
}

.box-big {
  border-radius: 20px;
  padding: 8px;
  display: inline-block;
}

/* LOGO */
.logo {
  white-space: nowrap;
}

.logo-lipp {
  border-radius: 10px;
  padding: 4px;
  background-color: #FFFFFF;
}

.logo-tv {
  border-radius: 10px;
  padding: 4px;
  background-color: #C4C4C4;
}

.logo img {
  max-height: 72px;
}

.live-now {
  border-radius: 10px;
  padding: 4px;
  background-color: #FF2828;
  min-width: 64px;
}

/* nav bar */
.nav-bar {
  user-select: none;
  position: sticky;
  top: 0;
  height: 30vh;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
  padding: 2.5%;

  /* Content is centered horizontally */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.nav-links, .logo-link {
  display: flex;
}

.nav-section {
  border-radius: 10px;
  padding: 4px;
}

.nav-section:hover {
  opacity: 0.6;
  cursor: pointer;
}

/* SECTION COLORS */
.about {
  background-color: #FFB800;
}

.bodytext-about {
  color:#FFB800;  
}

.program {
  background-color: #02C294;
}

.bodytext-program {
  color: #02C294;
}

.credits {
  background-color: #FFFFFF;
}

.bodytext-credits {
  color: #FFFFFF;
}

/* SECTIONS */
.section {
  display: flex;
  flex-flow: row wrap;
}

.section-inner {
  width: 95%;
  margin: 0 2.5%;
}

.section-inner-half {
  width: 45%;
  margin: 0 2.5%;
}

.section-inner-third {
  width: 28%;
  margin: 0 2.5%;
}

.title {
  margin-top: 1em;
  margin-bottom: 1em;
}

.section-title {
  margin-top: 2em;
  margin-bottom: 1em;
}

.program-title {
  margin-top: 2em;
  margin-bottom: 2em;
}

.credits-individual {
  margin-bottom: 1em;
}

.footer {
  width: 100%;
  margin-bottom: 4em;
}

/* override */
.credits-footing {
  margin-bottom: 2em;
}

.program-footing {
  margin-bottom: 0;
}

.about-heading {
  margin-top: 0;
}

/* container */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* twitch player */
.section-twitch {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 4em;
}

/* COLORS */
.program-red {
  background-color: #FF2828;
}

.program-magenta {
  background-color: #E353E5;
}

.program-yellow{
  background-color: #FFB800;
}

.program-blue {
  background-color: #287EFF;
}

.program-white {
  background-color: #FFFFFF;
}

/* IMAGES */
img.gif {
  width: 100%;
  border-radius: 10px;
}

/* MOBILE */
@media (max-width: 1024px) {
  .text-big {
    font-size: 42px;
    line-height: 32px;
  }

  .logo img {
    max-height: 32px;
  }

  .box-big, .section-twitch {
    border-radius: 10px;
  }

  .box-small, .logo-lipp, .logo-tv, .live-now, .nav-section {
    border-radius: 5px;
  }

  .section-inner-half {
    width: 90%;
  }

  .section-inner-third {
    width: 90%;
  }

  .nav-bar {
    height: 20vh;
  }

  .program-title {
    margin-bottom: 1em;
  }

  .program-title.text-small {
    margin-top: 0;
  }

  img.gif {
    border-radius: 5px;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .logo-link {
    justify-content: center;
    flex: 1;
  }
}